<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :search="search"
          :preloader="fetchingData"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/tech/pages"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fixed
            dark
            fab
            bottom
            right
            color="green"
            v-bind="attrs"
            v-on="on"
            @click="createOba()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Eine neue OBA anlegen</span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  name: "Oba",

  mixins: [apiRequest],

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
  },

  created: function () {
    this.getData();
  },

  data() {
    return {
      pageTitle: "OBA",
      pageDescription: "Verwalten der Umfrage",
      headers: [
        { text: "ID", value: "id", width: "50px" },
        { text: "Name", value: "name" },
        { text: "Im Einsatz", value: "inuse" },
        { text: "", value: "actions", sortable: false, width: "50px" },
      ],
      items: [],
      search: "",
      fetchingData: false,
    };
  },

  methods: {
    async createOba() {
      this.fetchingData = true;
      let response = await this.postRequest("obas");
      this.fetchingData = false;

      if (response.status === 200) {
        const result = response.data.data;
        this.items.unshift(result);
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("obas");
      this.fetchingData = false;

      if (response.status === 200) {
        const result = response.data.data;
        this.items = result;
      }
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.page-plugins.oba.:id",
          params: { id: value.itemId },
        });
      }
    },
  },
};
</script>
